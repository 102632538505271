import { fetchWebhookLog } from '@/api/webhookApi';
import PageHeader from '@/components/PageHeader';
import PageLoader from '@/loaders/PageLoader';
import { numberWithCommas } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';

export const LogsDetails = () => {
  const { id } = useParams();

  const log = useQuery({
    queryKey: ['log', { id }],
    queryFn: fetchWebhookLog
  });

  const detail = log?.data?.data;

  const handleReturn = () => {
    window.history.back();
  };

  if (log.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader title="Webhook Details" subtitle="View and manage webhooks" />
      <WebhookDetailsView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Source Account Name</h3>
                <p>{detail?.payload?.sourceAccountName || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Source Account Number</h3>
                <p>{detail?.payload?.sourceAccountNumber || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Source Bank Name</h3>
                <p>{detail?.payload?.sourceBankName || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Reference No:</h3>
                <p>{detail?.reference || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Transaction Remark</h3>
                <p>{detail?.payload?.tranRemarks || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Transaction Amount</h3>
                <p>₦{numberWithCommas(detail?.payload?.transactionAmount || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Fee</h3>
                <p>₦{numberWithCommas(detail?.payload?.feeAmount || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>VAT</h3>
                <p>₦{numberWithCommas(detail?.payload?.vatAmount || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Transaction Date/Time</h3>
                <p>{detail?.payload?.tranDateTime || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Created</h3>
                <p>{format(new Date(detail?.createdAt), 'do MMM, yyyy')}</p>
              </div>
            </div>
          </FormData>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Sender</h3>
                <p>{detail?.sender|| '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Account Number</h3>
                <p>{detail?.payload?.accountNumber || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Currency</h3>
                <p>{detail?.payload?.currency || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Session Id</h3>
                <p>{detail?.payload?.sessionId || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Settlement Id</h3>
                <p>{detail?.payload?.settlementId || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Settled Amount</h3>
                <p>₦{numberWithCommas(detail?.payload?.settledAmount || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Settlement Date/Time</h3>
                <p>{detail?.payload?.settlementDateTime || '-'}</p>
              </div>
            </div>
          </FormData>
        </DataView>
      </WebhookDetailsView>
    </>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'processing':
      return '#F4B740';
    case 'initiated':
      return '#F4B740';
    case 'inactive':
      return '#B91C1C';
    case 'declined':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;
const WebhookDetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 100px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
