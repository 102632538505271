import api from './api';

export const fetchTotalCount = async () => {
  const response = await api.get(`/admin/dashboard/bills/count`);
  return response.data;
};

export const fetchTotalPaid = async () => {
  const response = await api.get(`/admin/dashboard/bills/paid`);
  return response.data;
};

export const fetchUserCount = async () => {
  const response = await api.get(`/admin/dashboard/users/count`);
  return response.data;
}

export const fetchStatistics = async () => {
  const response = await api.get(`/admin/dashboard/bill-invoice/stat`)
  return response.data;
}

export const fetchTransactionLevel = async () => {
  const response = await api.get(`/admin/dashboard/transaction/level`)
  return response.data;
}