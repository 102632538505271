import api, { publicApi } from './api';

/**
 * * Login user ✅
 * @param {*} data - {email, password}
 * @returns {Object} - {user, tokens}
 */
export const loginUser = async (data) => {
  const response = await api.post('/admin/auth/login', data);
  return response.data;
};

/**
 * * Logout user ✅
 * @returns {*} - void
 */
export const logoutUser = async () => {
  const response = await api.get('/admin/auth/logout');
  return response.data;
};

/**
 * * Fetch user profile ✅
 * @returns {Object} - {user}
 */
export const fetchProfile = async () => {
  const response = await api.get('/admin/profile');
  return response.data;
};

/**
 * * Refreshes access token ✅
 * @returns {Object} - {user, tokens}
 */
export const refreshToken = async () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.log(error);
  }
};

export const forgotPassword = async (data) => {
  const response = await api.post(`/admin/auth/forgot-password`, data);
  return response.data;
};

export const resetPassword = async (data) => {
  const response = await api.patch(`/admin/auth/reset-password`, data);
  return response.data;
};
