import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AllTransactionsTable from './AllTransactionsTable';
import { CreditTransactions } from './CreditTransactions';
import { DebitTransactions } from './DebitTransactions';

export const TransactionTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  let view = searchParams.get('view');
  let tab = searchParams.get('tab');
  // let tab = searchParams.get('view');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      // setSearchParams('tab=table&view=all');
      setSearchParams({ tab: 'table', view: 'all' });
      // Optionally, you can also navigate to the new URL
      navigate('?tab=table&view=all');
    }
  }, [searchParams, navigate, setSearchParams]);

  const tabs = [
    {
      name: "All Transactions",
      path: "view=all",
      value: "all",
    },
    {
      name: "Credit Transactions",
      path: "view=credit",
      value: "credit",
    },
    {
      name: "Debit Transactions",
      path: "view=debit",
      value: "debit",
    }
  ];

  return (
    <>
      <TransactionTableView>
        <ContentView>
          <TabView>
            {tabs.map((tabItem) => (
              <button
                key={tabItem.path}
                className={`tabs ${view === tabItem.value ? 'active' : ''}`}
                // onClick={() => setSearchParams(`tab=table&${tabItem?.path}`)}
                onClick={() => setSearchParams({ tab: 'table', view: tabItem.value })}>
                {tabItem.name}
              </button>
            ))}
          </TabView>
          <TableView>
            {view === 'all' && <AllTransactionsTable />}
            {view === 'credit' && <CreditTransactions />}
            {view === 'debit' && <DebitTransactions />}
          </TableView>
        </ContentView>
      </TransactionTableView>
    </>
  );
}

const TransactionTableView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 10px;
`;

const TableView = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentView = styled.div`
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.line};
`;

const TabView = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  /* position: fixed; */
  z-index: 10;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
    color: ${(props) => props.theme?.colors?.secondary};
    }
  }
  
  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;