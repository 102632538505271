import { fetchLenders } from '@/api/lendersApi';
import { useSortableData } from '@/hooks/useSortableData';
import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-tr.icon.svg';
import { ReactComponent as FilterIcon } from '@/assets/icons/filter.icon.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrow-up.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import useOnClickOutside from "@/hooks/useClickOutside";
import { format } from "date-fns";
import useDebounce from "@/hooks/useDebounce";
import { motion, AnimatePresence } from "framer-motion";
import TableLoader from "@/loaders/TableLoader";
import { numberWithCommas } from "@/utils/helpers";
import { Oval } from "react-loader-spinner";
import RemoveLenderModal from './RemoveLenderModal';
// import LenderDetailsModal from './LenderDetailsModal';
import { usePermissions } from '@/hooks/usePermissions';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import UpdateDiscountModal from './UpdateDiscountModal';

const LendersTable = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const debouncedsearch = useDebounce(search, 500);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showRemoveLenderModal, setShowRemoveLenderModal] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const PAGE_LIMIT = 10;

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canUpdateUsers = hasPermission('users', 'update');
  // const canUpdateLender = hasPermission('lender', 'update');

  const handleUpdateLender = (id) => {
    const isAllowed = canUpdateUsers || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedUser(id);
      setShowRemoveLenderModal(true);
    }
  }

  const handleUpdateLenderDiscount = (obj) => {
    const isAllowed = canUpdateUsers || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedItem(obj);
      setShowDiscount(true);
      console.log(obj);
    }
  }

  const lenders = useQuery({
    queryKey: ['lenders'],
    queryFn: fetchLenders
  });

  // Sort table
  const { items, requestSort, sortConfig } = useSortableData(lenders?.data?.data?.users || []);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  if (lenders.isFetching && !search) {
    return <TableLoader />
  }

  return (
    <LendersTableView>
      <TableHead>
        <div className=''>
          <span>Name</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div>
          <span>Company</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className='email'>
          <span>Email</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div>
          <span>Date Created</span>
          <span className="icon">
            <ArrowUpIcon className="up" />
            <ArrowDownIcon className="down" />
          </span>
        </div>
        <div className="actions"></div>
      </TableHead>
      <TableBody>
        {items.map((item, i) => {
          return (
            <div key={item?.id} className="item">
              <div className=''>
                <span>{`${item?.firstName} ${item?.lastName}` || '--'}</span>
              </div>
              <div>
                {item?.companies?.length > 0 ? (
                  <span>{item?.companies[0]?.name || '-'}</span>
                ) : (
                  <span>-</span>
                )}
              </div>
              <div className='email'>
                <span>{item.email || '-'}</span>
                {/* <Status status={item?.status}>
                  <span className="dot"></span> {item?.status || '--'}
                </Status> */}
              </div>
              <div>
                <span>{format(new Date(item?.createdAt), 'do MMM, yyyy')}</span>
              </div>
              <div className="actions">
                <button
                  className='menu-icon'
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}
                >
                  <MoreIcon />
                </button>

                {/* ===== Dropdown Menu ====== */}
                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          navigate(`/lenders/${item?.id}`);
                      }}>
                        <span>View Lender</span>
                      </button>

                      <button
                        onClick={() => {
                          handleUpdateLenderDiscount(item);
                      }}>
                        <span>Update Discount</span>
                      </button>

                      <button
                        onClick={() => {
                          handleUpdateLender(item?.id);
                      }}>
                        <span>Remove As Lender</span>
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          )
        })}
      </TableBody>

      <RemoveLenderModal 
        showModal={showRemoveLenderModal}
        setShowModal={setShowRemoveLenderModal}
        selectedUser={selectedUser}
      />

      <UpdateDiscountModal 
        showModal={showDiscount}
        setShowModal={setShowDiscount}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </LendersTableView>
  )
}

export default LendersTable;

const LendersTableView = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 80px;
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-right: none;
  margin-top: 40px;
`;

const SearchView = styled.div`
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    margin-left: 10px;
    font-weight: 400;

    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

const FilterView = styled.div`
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .filter-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        font-size: 1.1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.black};
      }

      .done {
        background-color: transparent;
        color: ${({ theme }) => theme.colors?.secondary};
        border: none;
        outline: none;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: auto;
      }
    }

    .body {
      .item {
        width: 100%;
        margin-bottom: 20px;

        h3 {
          font-size: 1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors?.black};
          margin-bottom: 10px;
        }

        .input {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          input {
            margin-right: 10px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
          }
        }

        select {
          width: 100%;
          height: 40px;
          outline: none;
          border: 1px solid ${({ theme }) => theme.colors.line};
          border-radius: 6px;
          padding: 0 10px;
          font-size: 0.875rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors?.placeholder};
          cursor: pointer;
        }
      }
    }
  }

  .export-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow:
      0px 0px 2px rgba(125, 125, 125, 0.1),
      0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10;
    width: 300px;
    border: none;
    padding: 20px 12px;

    .body {
      margin-top: 20px;

      .item {
        width: 100%;
        margin-bottom: 20px;

        .date-input {
          gap: 0.4rem;
          display: flex;
          align-items: start;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: start;

          input {
            width: 100%;
            border: none;
            cursor: pointer;
            margin-right: 10px;
            border-radius: 6px;
            padding: 0.5rem 0.65rem;
            color: ${({ theme }) => theme.colors?.black};
            background-color: ${({ theme }) => theme.colors?.inputBackground};
          }
        }
      }

      .done {
        margin: 0;
        width: auto;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: 600;
        border-radius: 6px;
        padding: 0.6rem 2rem;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.white};
        background-color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }

  button {
    width: 120px;
    height: 100%;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  border-top: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .type {
    flex: 1.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .type {
      flex: 1.5;
    }

    .email {
      flex: 1;
      flex-wrap: wrap;

      span {
        text-transform: none;
      }
    }

    .actions {
      /* flex: 0.5; */
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 2rem 0;

    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;