import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

// hostname is used to check if the app is running on staging or production
const hostname = window.location.hostname.split('.')[0];

const baseURL =
  hostname === 'admin' ? import.meta.env.VITE_PROD_BASE_URL : import.meta.env.VITE_DEV_BASE_URL;

const api = axios.create({
  baseURL,
  timeout: 120000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
  }
});

export const publicApi = axios.create({
  baseURL:
    hostname === 'app' ? import.meta.env.VITE_PROD_BASE_URL : import.meta.env.VITE_DEV_BASE_URL,
  timeout: 120000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
  }
});

api.interceptors.request.use(
  async (config) => {
    const accessToken = await secureLocalStorage.getItem('ut');

    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAuthLogic = async (failedRequest) => {
  const refreshToken = await secureLocalStorage.getItem('rt');
  const data = {
    refreshToken: refreshToken
  };

  const options = {
    method: 'POST',
    data,
    url: `${baseURL}/admin/auth/refresh-token`
  };

  return axios(options)
    .then(async (tokenRefreshResponse) => {
      failedRequest.response.config.headers.Authorization =
        'Bearer ' + tokenRefreshResponse.data.data.accessToken;

      secureLocalStorage.setItem('ut', tokenRefreshResponse?.data?.data?.accessToken);
      secureLocalStorage.setItem('rt', tokenRefreshResponse?.data?.data?.refreshToken);

      return Promise.resolve();
    })
    .catch((e) => {
      secureLocalStorage.removeItem('ut');
      secureLocalStorage.removeItem('rt');
      secureLocalStorage.removeItem('auth');
    });
};

createAuthRefreshInterceptor(api, refreshAuthLogic, {});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(error);
  }
);

export default api;
