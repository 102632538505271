import PageHeader from '@/components/PageHeader';
import { usePermissions } from '@/hooks/usePermissions';
import React from 'react'
import Metrics from './components/Metrics';
import styled from 'styled-components';
import { EmptyState } from '@/components/EmptyState';

export const Metric = () => {
  const { hasPermission, superAdmin } = usePermissions();
  // const canReadMetrics = hasPermission('metrics', 'read');
  const isASuperAdmin = superAdmin(true);

  return (
    <>
      <PageHeader title="Metrics" subtitle="View and manage all metrics" />
      <MetricView>
        {/* {canReadMetrics || isASuperAdmin ? <Metrics /> : <EmptyState />} */}
        <Metrics />
      </MetricView>
    </>
  );
}

const MetricView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
`;