import api from "./api"

export const fetchWebhooks = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/admin/webhook?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchWebhook = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/webhook/single/${id}`);
  return response.data;
}

export const fetchWebHookLogs = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `admin/webhook/log?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchWebhookLog = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/webhook/log/${id}`);
  return response.data;
}

export const fetchWebhookReversals = async ({ queryKey }) => {
  const [, { page, limit, search }] = queryKey;
  let url = `/admin/webhook/reversal?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  const response = await api.get(url);
  return response.data;
}

export const fetchWebhookReversal = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/admin/webhook/single/${id}`);
  return response.data;
}